<template>
  <div>
    <div class="mb-2 items-center">
      <vs-button class="ml-auto" @click="popupAddItem = true; editMode = false" size="small">Add Industry</vs-button>
    </div>
    <vx-card no-shadow>
      <vs-table
        :max-items="descriptionItems[0]"
        pagination
        :data="list"
        description
        search
        :description-items="descriptionItems"
        description-title="Registries">

        <template slot="header">
          <h5 class="flex items-center mb-2">
            <feather-icon icon="TagIcon" class="w-5 h-5 mr-1"></feather-icon>
            <span>Industries </span>
          </h5>
        </template>
        <template slot="thead">
          <vs-th>
            Title
          </vs-th>
          <vs-th>
            Action
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
            <vs-td :data="data[indextr].title">
              {{data[indextr].title}}
            </vs-td>
            <vs-td class="whitespace-no-wrap">
                <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="editItem(tr)" />
                <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteItem(tr.id)" />
              </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup class="holamundo" @close="initializeAll" 
        :title="editMode ? 'Edit industry':'Add industry'"
        :active.sync="popupAddItem">
        <industry-form v-if="popupAddItem"
          :editItem="showItem"
          :editMode="editMode"
          @updateItem="updateItem($event)"
          @addItem="addItem($event)"
          @closeMe="initializeAll"/>
      </vs-popup>
    </vx-card>
  </div>
</template>

<script>
import IndustryForm from '@/components/forms/IndustryForm.vue'

export default {
  components:{
    IndustryForm
  },
  data() {
    return {
      descriptionItems: [20,5,15,50],
      editMode: false,
      popupAddItem: false,
      showItem: null,
      list:[]
    }
  },
  methods: {
    editItem(item){
      this.showItem = item;
      this.editMode = true
      this.popupAddItem = true
    },
    updateItem(item){
      Object.assign(this.showItem, item)
    },
    addItem(item){
      this.list.unshift(item)
    },
    deleteItem(itemId){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'The selected industry will be removed!',
        accept: () => this.acceptDelete(itemId)
      })
    },
    acceptDelete(itemId){
      this.$http.delete(`/industries/${itemId}/delete`)
      .then((response) => {
        if(response.data.success){
          const itemIndex = this.list.findIndex((item) => item.id == itemId)
          this.list.splice(itemIndex, 1)
          this.initializeAll();
        }
      })
    },
    fetchItems() {
      this.$http.get(`/industries`)
        .then((response) => {
          if(response.data.success){
            this.list = response.data.data
          }
        })
    },
    initializeAll(){
      this.popupAddItem = false
      this.editMode = false
      this.showItem = null
      this.$validator.reset()
    }
  },
  created(){
    this.fetchItems()
  }
}
</script>
